import React from "react";
import Base from "../Base/Base";
// import HopBoho from "../components/Hop_Boho";
import AllEvents from "../components/All_Events";
const Events = () => {
  return (
    <Base>
      <AllEvents />

    </Base>
  );
};

export default Events;
