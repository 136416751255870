import { is_authenticated } from "../helpers/index";
import Axios from "axios";
import { USER_PATH, BASE_URL } from "./urlsandpath";

let token = "";

const gettoken = () => {
  const user = is_authenticated();
  token = user.token;
};

export const createuser = async (data) => {
  gettoken();
  try {
    const body = {
      token,
      email: data.email,
      username: data.username,
      role: data.role,
    };
    const res = await Axios.post(BASE_URL + USER_PATH.create, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const updateuser = async (data) => {
  gettoken();
  try {
    const body = {
      token,
      update: {
        username: data.username,
        email: data.email,
        role: data.role,
        displayname: data.displayname,
      },
    };
    const res = await Axios.put(BASE_URL + USER_PATH.update, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const deleteuser = async (email) => {
  gettoken();
  try {
    const body = {
      token,
      email,
    };

    const res = await Axios.delete(BASE_URL + USER_PATH.delete, { data: body });
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    console.log(r);
    return r;
  }
};

export const getusers = async () => {
  gettoken();
  try {
    const res = await Axios.get(BASE_URL + USER_PATH.get + `?token=${token}`);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const logoutuser = async (email) => {
  gettoken();
  try {
    const body = {
      token,
      email,
    };
    const res = await Axios.patch(BASE_URL + USER_PATH.logout, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};
