import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { get_status, togglestatus } from "../apis/preview";

const Singlepreviewarticle = ({ title, imagesrc, aid, setSnakbaropen, setSnaksev, setSnakmsg, city }) => {

    const [live, setLive] = useState(false)

    // Methods

    const clickhandler = async (e) => {
        e.preventDefault();
        const response = await togglestatus(aid);
        if (response.error) {
            setSnaksev("error");
            setSnakmsg(response.message);
            setSnakbaropen(true);
            return;
        }
        if (response.statusCode === 200) {
            setSnaksev("success");
            setSnakmsg("Status Updated Successfully!");
            setSnakbaropen(true);
            setLive(response.response.ispreviewlive);
            return;
        } else {
            setSnaksev("error");
            setSnakmsg("Error Updating Status");
            setSnakbaropen(true);
        }
    };

    const getarticlestatus = async () => {
        const response = await get_status(aid);
        if (response.error) {
            setSnaksev("error");
            setSnakmsg(response.message);
            setSnakbaropen(true);
            return;
        }
        if (response.statusCode === 200) {
            setSnaksev("success");
            setSnakmsg("Status Updated Successfully!");
            setSnakbaropen(true);
            setLive(response.response.ispreviewlive);
            return;
        } else {
            setSnaksev("error");
            setSnakmsg("Error Updating Status");
            setSnakbaropen(true);
        }
    }

    useEffect(() => {
        getarticlestatus()
        // eslint-disable-next-line
    }, [])

    return (
        <Card
            className="SingleArticleCard"
            sx={{ maxWidth: 220, marginBottom: 3, position: "relative" }}
        >
            <CardMedia
                component="img"
                height="220"
                image={imagesrc}
                alt={title}
            />
            <CardContent>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: 0,
                            alignItems: "center",
                            padding: 0,
                            borderBottom: "1px dotted #212121",

                        }}
                    >
                        <p className="article_card_title">{title}</p>
                    </Box>

                </Box>
            </CardContent>

            <CardActions disableSpacing>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",

                    }}
                ><Box sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}>{live && <Box>
                    <Tooltip title="Preview Link" placement="bottom">
                        <a href={`/preview-article/${city}/${aid}`} target="_blank" rel="noreferrer">
                            <img
                                width="25px"
                                src="https://res.cloudinary.com/purnesh/image/upload/v1677581485/SoLinksDashboard/https.png"
                                alt="link"
                            />
                        </a>
                    </Tooltip>
                </Box>}</Box>

                    <Box sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}>  <Box

                    >

                            <Tooltip title="Active Status" placement="bottom">
                                <IconButton onClick={(e) => clickhandler(e)} >
                                    <img

                                        src={
                                            live
                                                ? "https://res.cloudinary.com/purnesh/image/upload/v1677565630/SoLinksDashboard/eye.png"
                                                : "https://res.cloudinary.com/purnesh/image/upload/v1677735508/SoLinksDashboard/vision.png"
                                        }
                                        alt="Edit"
                                        width="18px"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box></Box>

                </Box>
            </CardActions>

        </Card>
    )
}

export default Singlepreviewarticle
