import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import { Box } from "@mui/material"
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Avatar from '@mui/material/Avatar';
import { get_status, getsinglearticle } from '../apis/preview';
import SOLOGO from "../assets/so_logo_side.jpg"

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Previewarticle = () => {
    let { articleid, city } = useParams();
    const [articledata, setArticledata] = useState({})
    const [live, setLive] = useState(false)

    // Snakbar State
    const [snakbaropen, setSnakbaropen] = useState(false);
    const [snaksev, setSnaksev] = useState("success");
    const [snakmsg, setSnakmsg] = useState("");

    // Snakbar Handlers
    const handleSnakbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnakbaropen(false);
    };

    const replaceimg = (s) => {
        return s.replaceAll('<p><img', "<p class='a-img-wrapper'><img class='a-image'")
    }

    const fetcharticle = async () => {



        let response = { error: true, message: "Error Fetching Data" };

        response = await getsinglearticle(articleid, city);

        if (response.error) {
            setSnaksev("error");
            setSnakmsg(response.message);
            setSnakbaropen(true);
            return;
        }

        if (response.data) {
            // console.log(response.data);
            setArticledata(response.data[0])
            setSnaksev("success");
            setSnakmsg("Success");
            setSnakbaropen(true);
        } else {
            setSnaksev("error");
            setSnakmsg("Error Fetching Data");
            setSnakbaropen(true);
        }
    };

    const getarticlestatus = async () => {
        const response = await get_status(articleid);
        if (response.error) {
            setSnaksev("error");
            setSnakmsg(response.message);
            setSnakbaropen(true);
            return;
        }
        if (response.statusCode === 200) {
            setLive(response.response.ispreviewlive);
            return;
        } else {
            setSnaksev("error");
            setSnakmsg("Error Updating Status");
            setSnakbaropen(true);
        }
    }

    useEffect(() => {
        getarticlestatus()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (live) {
            fetcharticle()
        }
        // eslint-disable-next-line
    }, [live])

    useEffect(() => {
        if (articledata.title) {
            document.title = articledata.title
        }
    }, [
        articledata
    ])



    if (live) {
        return (
            <Box sx={{ background: "#f6f5fa", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                <Box className="article_section">
                    <Box>
                        <img className="header-image" src={articledata.imageUrl} alt={articledata.title} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <h1 className='title-text'>{articledata.title}</h1>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", paddingX: "1rem" }}>
                        <Box className="auther_info">
                            <Avatar alt="Socity" src={SOLOGO} sx={{ width: "40px", height: "40px" }} />
                            <Box sx={{ paddingLeft: "1rem" }}>
                                <p className='a-text'>So City</p>
                                <p className='a-text'> 01 Jan 2023</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="a-content">
                        {articledata.introduction && parse(replaceimg(articledata.introduction))}
                    </Box>

                </Box>
                <Snackbar
                    open={snakbaropen}
                    autoHideDuration={4000}
                    onClose={handleSnakbarClose}
                >
                    <Alert
                        onClose={handleSnakbarClose}
                        severity={snaksev}
                        sx={{ width: "100%" }}
                    >
                        {snakmsg}
                    </Alert>
                </Snackbar>
            </Box >
        )
    } else {
        return <Box sx={{
            display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh"
        }}>Article Not Available for Preview</Box>
    }
}

export default Previewarticle
