import React from "react";
import Base from "../Base/Base";
import Setting from "../components/Setting";

const Settings = () => {
  return (
    <Base>
      <Setting />
    </Base>
  );
};

export default Settings;
