import { is_authenticated } from "../helpers/index";
import Axios from "axios";
import { JOB_PATH, BASE_URL } from "./urlsandpath";

let token = "";

const gettoken = () => {
  const user = is_authenticated();
  token = user.token;
};

export const createjob = async (data) => {
  gettoken();
  try {
    const body = {
      token,
      job: data,
    };
    const res = await Axios.post(BASE_URL + JOB_PATH.create, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const updatejob = async (data) => {
  gettoken();
  try {
    const body = {
      token,
      job: data,
    };
    const res = await Axios.put(BASE_URL + JOB_PATH.update, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const deletejob = async (data) => {
  gettoken();
  try {
    const res = await Axios.delete(
      BASE_URL + JOB_PATH.remove + `?token=${token}&jobid=${data}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const fetchjobs = async () => {
  try {
    const res = await Axios.get(BASE_URL + JOB_PATH.get);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};
