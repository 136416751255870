export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_2 = process.env.REACT_APP_BASE_URL_2;

export const AUTH_PATH = {
  getcode: "/user/get-code",
  verifycode: "/user/verify-code",
};

export const temp = () => { };

export const USER_PATH = {
  get: "/user/list",
  create: "/user/create",
  update: "/user/update",
  delete: "/user/delete",
  logout: "/user/logout",
};


export const EVENT_PATH = {
  geteventlist: "/event/fetch-event-list",
  geteventdata: "/event/fetch-event-data", // eventid as query
  create: "/event/create",
  update: "/event/update",
  updateeventlist: "/event/update-event-list",
  deleteeventlist: "/event/delete-event-list",
  createeventlist: "/event/create-event-list",

};
export const JOB_PATH = {
  get: "/job/list",
  create: "/job/create",
  update: "/job/update",
  togglestatus: "/job/toggle-status",
  remove: "/job/delete",
};

export const ARTICLE_PATH = {
  create: "/article/create",
  get: "/article/list", //Skip and Limit in query format
  update: "/article/update",
  togglestatus: "/article/toggle-status",
  uploadimage: "/article/upload-banner",
  getimages: "/article/get-images",
};

export const PREVIEW_PATH = {
  list: "/articles/preview/list",//Skip and Limit in query format BASE_URL_2
  singlearticle: "/articles/preview", // city / aid in params BASE_URL_2
  togglestatus: "/preview/toggle-status",
  getstatus: "/preview/get-status"
};
