import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { createjob, deletejob, fetchjobs, updatejob } from "../apis/jobs";

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

// Status For Select
const status = [
  {
    value: true,
    label: "Active",
  },

  {
    value: false,
    label: "Inactive",
  },
];
const Jobs = () => {
  // States
  // Modal State
  const [open, setOpen] = useState(false);
  const [isjobupdate, setIsjobupdate] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 650;
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  // Job Data State

  const [jobdata, setJobdata] = useState({
    jobid: "",
    buttontext: "",
    link: "",
    isactive: false,
  });

  const [reload, setReload] = useState(0);

  // Snakbar State
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snaksev, setSnaksev] = useState("success");
  const [snakmsg, setSnakmsg] = useState("");

  // Job List State
  const [joblist, setJoblist] = useState([]);

  // Methods
  // Modal Handler
  const handleClickOpen = (isupdate, data) => {
    if (isupdate === true) {
      setIsjobupdate(true);
      setJobdata({
        ...jobdata,
        jobid: data.jobid,
        buttontext: data.buttontext,
        link: data.link,
        isactive: data.isactive,
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (isjobupdate) {
      setIsjobupdate(!isjobupdate);
    }
    setOpen(false);
  };

  // Snakbar Handlers
  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbaropen(false);
  };

  // Job State Change Handler

  const changehandler = (data, field) => {
    setJobdata({ ...jobdata, [field]: data });
  };

  // Save Job

  const savehandler = async (e) => {
    e.preventDefault();

    const temp = { ...jobdata };

    if (jobdata.buttontext.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Button Text");
      setSnakbaropen(true);
      return;
    }
    if (jobdata.link.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Form Link");
      setSnakbaropen(true);
      return;
    }

    let response = { error: true, message: "Something Went Wrong" };

    if (isjobupdate) {
      response = await updatejob(temp);
    } else {
      delete temp.jobid;
      response = await createjob(temp);
    }

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("Job Saved");
      setSnakbaropen(true);
      handleClose();
      setJobdata({ jobid: "", buttontext: "", link: "", isactive: false });
      setReload(reload + 1);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Saving Job!");
      setSnakbaropen(true);
    }
  };

  // Getting Job List

  const getjoblist = async () => {
    let response = { error: true, message: "Error Fetching List" };

    response = await fetchjobs();

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setJoblist(response.response);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Fetching Jobs List!");
      setSnakbaropen(true);
    }
  };

  // Delete Job

  const deletehandler = async (id) => {
    let response = { error: true, message: "Error Fetching List" };

    response = await deletejob(id);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("Success Deleting Jobs!");
      setSnakbaropen(true);
      setReload(reload + 1);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Deleting Jobs!");
      setSnakbaropen(true);
    }
  };

  // Life cycle

  useEffect(() => {
    getjoblist();
  }, []);

  useEffect(() => {
    if (reload > 0) getjoblist();
  }, [reload]);

  // Debug

  useEffect(() => {
    console.log(joblist);
  }, [joblist]);

  return (
    <Base>
      <main className="job-main">
        <section className="job-header">
          <Button
            className="addJobsButton"
            variant="outlined"
            onClick={handleClickOpen}
            sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
            startIcon={
              <img
                src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
                alt="add"
                width="20px"
              />
            }
          >
            Add New Jobs
          </Button>
        </section>
        {width < breakpoint ? (
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            className="mobilejob-list"
            sx={{
              width: "100%",
            }}
          >
            {joblist.length > 0 &&
              joblist.map((j) => (
                <div className="mobileapplyJobDiv" key={j.jobid}>
                  <div className="mobileapplyJobDiv_text">
                    <p className="mobileapplyJobDiv_text_label">Button Text</p>
                    <h4 className="mobileapplyJobDiv_text_val">
                      {j.buttontext}
                    </h4>
                  </div>

                  <div className="mobileapplyJobDiv_text">
                    <Tooltip title={j.link} placement="top">
                      <Button href={j.link} target="_blank" rel="noreferrer">
                        <img
                          className="mobilehopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677569180/SoLinksDashboard/http_1.png"
                          alt="link"
                          height={30}
                          width={30}
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                      <Button
                        onClick={() => {
                          handleClickOpen(true, j);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          className="mobilehopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                          alt="Edit"
                          height={25}
                          width={25}
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button
                        onClick={() => {
                          deletehandler(j.jobid);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          className="mobilehopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1678794783/SoLinksDashboard/trash.png"
                          alt="Edit"
                          height={25}
                          width={25}
                        />
                      </Button>
                    </Tooltip>
                  </div>

                  {/* <div className="mobileLiveLink">
                  
                  </div> */}

                  {/* <div className="mobileLiveLink">
                    
                  </div> */}

                  {j.isactive ? (
                    <div className="mobileapplyJobDiv_status">Active</div>
                  ) : (
                    <div className="mobileapplyJobDiv_statusinActive">
                      Inactive
                    </div>
                  )}
                </div>
              ))}
          </Stack>
        ) : (
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            className="job-list"
            sx={{
              width: "100%",
            }}
          >
            {joblist.length > 0 &&
              joblist.map((j) => (
                <div className="applyJobDiv" key={j.jobid}>
                  <div className="applyJobDiv_text">
                    <p className="applyJobDiv_text_label">Button Text</p>
                    <h4 className="applyJobDiv_text_val">{j.buttontext}</h4>
                  </div>

                  <Tooltip title={j.link} placement="top">
                    <div className="applyJobDiv_text">
                      <a href={j.link} target="_blank" rel="noreferrer">
                        <img
                          className="hopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677569180/SoLinksDashboard/http_1.png"
                          alt="link"
                          height={30}
                          width={30}
                        />
                      </a>
                    </div>
                  </Tooltip>

                  <div className="LiveLink">
                    <Tooltip title="Edit" placement="top">
                      <Button
                        onClick={() => {
                          handleClickOpen(true, j);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          className="hopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                          alt="Edit"
                          height={25}
                          width={25}
                        />
                      </Button>
                    </Tooltip>
                  </div>

                  <div className="LiveLink">
                    <Tooltip title="Delete" placement="top">
                      <Button
                        onClick={() => {
                          deletehandler(j.jobid);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          className="hopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1678794783/SoLinksDashboard/trash.png"
                          alt="Edit"
                          height={25}
                          width={25}
                        />
                      </Button>
                    </Tooltip>
                  </div>

                  {j.isactive ? (
                    <div className="applyJobDiv_status">Active</div>
                  ) : (
                    <div className="applyJobDiv_statusinActive">Inactive</div>
                  )}
                </div>
              ))}
          </Stack>
        )}

        {/* for Mobile */}
      </main>

      {/* Modal */}

      <Modal
        className="Modal"
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            className="ModalBox"
            sx={style}
            component="form"
            noValidate
            autoComplete="off"
          >
            <Typography
              sx={{
                minWidth: 100,
                textAlign: "center",
                backgroundColor: "black",
                color: "#fff",
                padding: 2,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {isjobupdate ? "Update Job" : "Add New Jobs"}
            </Typography>
            <div className="ModalForm">
              <TextField
                id="outlined-basic"
                label="Button Text"
                variant="outlined"
                size="small"
                fullWidth
                value={jobdata.buttontext}
                onChange={(e) => changehandler(e.target.value, "buttontext")}
              />
              <TextField
                id="outlined-basic"
                label="Paste The Job Link Here"
                variant="outlined"
                size="small"
                fullWidth
                value={jobdata.link}
                onChange={(e) => changehandler(e.target.value, "link")}
              />
              <TextField
                id="outlined-select-currency-native"
                select
                label="Select Status For the Job"
                size="small"
                fullWidth
                SelectProps={{
                  native: true,
                }}
                value={jobdata.isactive}
                onChange={(e) => changehandler(e.target.value, "isactive")}
              >
                {status.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <div className="modalFormButton">
                <Button
                  variant="outlined"
                  onClick={(e) => savehandler(e)}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    minWidth: "120px",
                  }}
                  startIcon={
                    <img
                      className="hopbohoimg"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/save.png"
                      alt="add"
                      width="25px"
                    />
                  }
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  // onClick={close}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    minWidth: "120px",
                  }}
                  startIcon={
                    <img
                      className="hopbohoimg"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/cross-mark.png"
                      alt="add"
                      width="25px"
                    />
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        open={snakbaropen}
        autoHideDuration={4000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={snaksev}
          sx={{ width: "100%" }}
        >
          {snakmsg}
        </Alert>
      </Snackbar>
    </Base>
  );
};

export default Jobs;
