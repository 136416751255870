import React, { useState } from "react";
import Base from "../Base/Base";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { is_authenticated } from "../helpers/index";
import { logoutuser } from "../apis/users";
import { useNavigate } from "react-router-dom";

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Setting = () => {
  const user = is_authenticated();
  const navigate = useNavigate();

  // Snakbar State
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snaksev, setSnaksev] = useState("success");
  const [snakmsg, setSnakmsg] = useState("");

  // Snakbar Handlers
  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbaropen(false);
  };

  const trylogout = async (e, email) => {
    e.preventDefault();

    let response = { error: true, message: "Error Logging-Out!" };

    response = await logoutuser(email);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("User Logged-Out Redirecting!");
      setSnakbaropen(true);
      if (window !== "undefined") {
        if (window.localStorage && window.localStorage.getItem("user")) {
          window.localStorage.removeItem("user");
        }
      }
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Logging-Out!");
      setSnakbaropen(true);
    }
  };
  return (
    <Base>
      <main className="job-main">
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          className="job-list"
          sx={{
            width: "100%",
          }}
        >
          <div className="HopBohoDiv">
            <div className="applyJobDiv_text">
              <p className="applyJobDiv_text_label">
                <Tooltip title="Username" placement="top">
                  <img
                    // className="IconButtonJob"
                    src="https://res.cloudinary.com/purnesh/image/upload/v1677828136/SoLinksDashboard/id-card_1.png"
                    alt="User Name"
                    height={25}
                    width={25}
                  />
                </Tooltip>
              </p>
              <h4 className="applyJobDiv_text_val">{user.username}</h4>
            </div>
            <div className="applyJobDiv_text">
              <p className="applyJobDiv_text_label">
                <Tooltip title="Display Name" placement="top">
                  <img
                    // className="IconButtonJob"
                    src="https://res.cloudinary.com/purnesh/image/upload/v1678867453/solinks/spkfisvmpjr7pgvc8jwq.png"
                    alt="User Name"
                    height={25}
                    width={25}
                  />
                </Tooltip>
              </p>
              <h4 className="applyJobDiv_text_val">
                {user.displayname || "Please Update Display Name"}
              </h4>
            </div>

            <div className="applyJobDiv_text">
              <p className="applyJobDiv_text_label">
                <Tooltip title="Email" placement="top">
                  <img
                    src="https://res.cloudinary.com/purnesh/image/upload/v1677828137/SoLinksDashboard/email.png"
                    alt="Email"
                    height={25}
                    width={25}
                  />
                </Tooltip>
              </p>

              <h4 className="applyJobDiv_text_val">{user.email}</h4>
            </div>
            <div className="applyJobDiv_text">
              <Tooltip title="Logout" placement="top">
                <Button
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    trylogout(e, user.email);
                  }}
                >
                  <img
                    className="hopbohoimg"
                    src="https://res.cloudinary.com/purnesh/image/upload/v1677843630/SoLinksDashboard/emergency-exit.png"
                    alt="Logout"
                    height={30}
                    width={30}
                  />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Stack>
      </main>
      <Snackbar
        open={snakbaropen}
        autoHideDuration={4000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={snaksev}
          sx={{ width: "100%" }}
        >
          {snakmsg}
        </Alert>
      </Snackbar>
    </Base>
  );
};

export default Setting;
