import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../components/Login";
// import Boho from "../pages/Boho";
import Events from "../pages/Events";
import Settings from "../pages/Settings";
import Jobs from "../pages/Jobs";
import Articles from "../pages/Articles";
import Users from "../pages/Users";
import Overview from "../pages/Overview";
import ProtectedRoute from "./ProtectedRoutes";
import { is_authenticated } from "../helpers/index";
import ArticlePreview from "../pages/ArticlePreview";
import Previewarticle from "../pages/Previewarticle";
function Main() {
  useEffect(() => {
    const userData = is_authenticated();
    if (is_authenticated()) {
      if (userData.expiringTime < Date.now()) {
        if (window !== "undefined") {
          if (window.localStorage) {
            window.localStorage.removeItem("token");
            window.localStorage.setItem("expired", true);
            window.location.reload();
          }
        }
      }
    }
  }, []); //eslint-disable-line
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          exact
          path="/preview-article/:city/:articleid"
          element={<Previewarticle />}
        />

        <Route
          exact
          path="/users"
          element={<ProtectedRoute component={Users} />}
        />

        <Route
          exact
          path="/overview"
          element={<ProtectedRoute component={Overview} />}
        />

        <Route
          exact
          path="/articles"
          element={<ProtectedRoute component={Articles} />}
        />

        {/* <Route
          exact
          path="/boho"
          element={<ProtectedRoute component={Boho} />}
        /> */}

        <Route
          exact
          path="/events"
          element={<ProtectedRoute component={Events} />}
        />

        <Route
          exact
          path="/jobs"
          element={<ProtectedRoute component={Jobs} />}
        />
        <Route
          exact
          path="/settings"
          element={<ProtectedRoute component={Settings} />}
        />
        <Route
          exact
          path="/preview/articles/list"
          element={<ProtectedRoute component={ArticlePreview} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
