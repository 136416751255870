import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";

import SideBar from "../components/Sidebar";
import MobileSidebar from "../components/MobileSidebar";
function Base({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 650;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      {width < breakpoint ? <MobileSidebar /> : <SideBar />}
      <Box
        className="BaseSidebar"
        sx={{
          marginTop: "4rem",
          marginBottom: width < breakpoint ? "3rem" : 1,
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Base;
