import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { dateconversion } from "../helpers";
import { togglestatus } from "../apis/articles";

const Singlearticle = ({
  handleOpen,
  article,
  setSnaksev,
  setSnakmsg,
  setSnakbaropen,
}) => {
  // Is Live State For API Dynamic View
  const [islive, setLive] = useState(article.islive);

  // Methods

  const clickhandler = async (e) => {
    e.preventDefault();
    const response = await togglestatus(article.articleid);
    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }
    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("Status Updated Successfully!");
      setSnakbaropen(true);
      setLive(response.response.islive);
      return;
    } else {
      setSnaksev("error");
      setSnakmsg("Error Updating Status");
      setSnakbaropen(true);
    }
  };

  useEffect(() => {}, []);
  return (
    <Card
      className="SingleArticleCard"
      sx={{ maxWidth: 220, marginBottom: 3, position: "relative" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 1,
          left: 0,
          padding: 1,
          fontSize: "2rem",
          color: "rgba(255,255,255,1)",
          fontWeight: "bold",
        }}
      >
        {"#" + article.articlenumber}
      </Box>
      {islive && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            fontSize: "0.7rem",
            minWidth: "60px",
            background: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
            padding: "0.1rem 0.5rem",
            color: "rgba(0,0,0,0.5)",
            fontWeight: 600,
            borderRadius: "0.1rem",
          }}
        >
          Active
        </Box>
      )}

      {!islive && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            background: "linear-gradient(to right, #fa709a 0%, #fee140 100%)",
            padding: "0.1rem 0.5rem",
            fontSize: "0.7rem",
            minWidth: "60px",
            color: "rgba(0,0,0,0.5)",
            fontWeight: 600,
            borderRadius: "0.1rem",
          }}
        >
          Inactive
        </Box>
      )}
      {article.isspecial && (
        <Box
          sx={{
            position: "absolute",
            top: 25,
            right: 0,
            background: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
            fontSize: "0.7rem",
            minWidth: "60px",
            padding: "0.1rem 0.5rem",
            color: "rgba(0,0,0,0.5)",
            fontWeight: 600,
            borderRadius: "0.1rem",
          }}
        >
          Special
        </Box>
      )}

      <CardMedia
        component="img"
        height="220"
        image={article.imagelink}
        alt={article.id}
      />
      <CardContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
              alignItems: "center",
              padding: 0,
              borderBottom: "1px dotted #212121",
              marginBottom: 1,
            }}
          >
            <p className="article_card_title"> {article.shorttitle}</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 1,
              alignItems: "center",
            }}
          >
            <p className="article_card_text">Creation Date</p>
            <p className="article_card_date">
              {article.creationdate
                ? dateconversion(article.creationdate)
                : "-"}
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="article_card_text">Last Updated</p>
            <p className="article_card_date">
              {article.lastupdated ? dateconversion(article.lastupdated) : "-"}
            </p>
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Tooltip title={article.articlelink} placement="bottom">
              <a href={article.articlelink} target="_blank" rel="noreferrer">
                <img
                  width="25px"
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677581485/SoLinksDashboard/https.png"
                  alt="link"
                />
              </a>
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Tooltip title="Edit" placement="bottom">
              <IconButton onClick={() => handleOpen(true, article)}>
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                  alt="Edit"
                  width="18px"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={islive ? "Inactive" : "Active"} placement="bottom">
              <IconButton onClick={(e) => clickhandler(e)}>
                <img
                  src={
                    islive
                      ? "https://res.cloudinary.com/purnesh/image/upload/v1677735508/SoLinksDashboard/vision.png"
                      : "https://res.cloudinary.com/purnesh/image/upload/v1677565630/SoLinksDashboard/eye.png"
                  }
                  alt="Edit"
                  width="18px"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default Singlearticle;
