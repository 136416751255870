import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import { FormControl, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { createuser, getusers, updateuser, deleteuser } from "../apis/users";
import { is_authenticated } from "../helpers/index";

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const STORED_USER = is_authenticated();

const User = () => {
  // States
  // Modal State
  const [open, setOpen] = useState(false);

  // Reload
  const [reload, setReload] = useState(0);

  // User State

  const [userform, setUserform] = useState({
    email: "",
    username: "",
    role: "normal",
    displayname: "",
  });

  const [userlist, setUserlist] = useState([]);

  const [isuserupdate, setIsuserupdate] = useState(false);

  // Snakbar State
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snaksev, setSnaksev] = useState("success");
  const [snakmsg, setSnakmsg] = useState("");

  // Methods
  // Modal Handler
  const handleClickOpen = (isupdate, data) => {
    if (isupdate === true) {
      setIsuserupdate(true);
      setUserform({
        ...userform,
        email: data.email,
        username: data.username,
        role: data.role,
        displayname: data.displayname,
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (isuserupdate) {
      setIsuserupdate(!isuserupdate);
      setUserform({ email: "", username: "", role: "normal", displayname: "" });
    }
    setOpen(false);
  };

  // Snakbar Handlers
  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbaropen(false);
  };

  // User Form State Change Handler

  const changehandler = (data, field) => {
    setUserform({ ...userform, [field]: data });
  };

  // check auth

  const checkuserauth = (u) => {
    if (u.email === STORED_USER.email) {
      return true;
    } else if (STORED_USER.role === "normal") {
      return true;
    }
    return false;
  };

  // Save Job

  const savehandler = async (e) => {
    e.preventDefault();

    const temp = { ...userform };
    temp.username = userform.username.replaceAll(" ", "").toLowerCase();
    if (temp.username === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Username ");
      setSnakbaropen(true);
      return;
    }
    if (userform.email.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Email");
      setSnakbaropen(true);
      return;
    }

    let response = { error: true, message: "Something Went Wrong" };

    if (isuserupdate) {
      response = await updateuser(temp);
    } else {
      delete temp.displayname;
      response = await createuser(temp);
    }

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("User Saved");
      setSnakbaropen(true);
      handleClose();
      setUserform({ email: "", username: "", role: "normal" });
      setReload(reload + 1);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Saving User!");
      setSnakbaropen(true);
    }
  };

  // fetch user

  const getuserlist = async () => {
    let response = { error: true, message: "Error Fetching List" };

    response = await getusers();

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setUserlist(response.response);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Fetching User List!");
      setSnakbaropen(true);
      return;
    }
  };

  // Delete User
  const deleteuserhandle = async (e, email) => {
    e.preventDefault();

    let response = { error: true, message: "Error Deleting User" };

    response = await deleteuser(email);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("User Deleted!");
      setSnakbaropen(true);
      setReload(reload + 1);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Deleting User!");
      setSnakbaropen(true);
    }
  };

  // Life Cycle

  useEffect(() => {
    getuserlist();
  }, []);

  // Refresh
  useEffect(() => {
    if (reload > 0) getuserlist();
  }, [reload]);

  return (
    <Base>
      <main className="job-main">
        <section className="job-header">
          <Button
            className="addJobsButton"
            variant="outlined"
            onClick={handleClickOpen}
            sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
            startIcon={
              <img
                src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
                alt="add"
                width="20px"
              />
            }
          >
            Add New User
          </Button>

          <Modal
            className="Modal"
            keepMounted
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box
                className="ModalBox"
                sx={style}
                component="form"
                noValidate
                autoComplete="off"
              >
                <Typography
                  sx={{
                    minWidth: 100,
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "#fff",
                    padding: 2,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {isuserupdate ? "Update User" : "Add New Users"}
                </Typography>
                <div className="ModalForm">
                  <TextField
                    id="outlined-basic"
                    label="Username "
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={userform.username}
                    onChange={(e) => changehandler(e.target.value, "username")}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={userform.email}
                    onChange={(e) => changehandler(e.target.value, "email")}
                  />
                  {isuserupdate && (
                    <TextField
                      id="outlined-basic"
                      label="Display Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={userform.displayname}
                      onChange={(e) =>
                        changehandler(e.target.value, "displayname")
                      }
                    />
                  )}
                  <Stack direction="row" spacing={3}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        label="Role"
                        variant="outlined"
                        size="small"
                        value={userform.role}
                        onChange={(e) => changehandler(e.target.value, "role")}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="superadmin">Super Admin</MenuItem>
                        <MenuItem value="normal">Normal User</MenuItem>
                      </TextField>
                    </FormControl>
                  </Stack>
                  <div className="modalFormButton">
                    <Button
                      className="addJobsSaveButton"
                      variant="outlined"
                      onClick={(e) => savehandler(e)}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                        minWidth: "120px",
                      }}
                      startIcon={
                        <img
                          className="hopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/save.png"
                          alt="add"
                          width="25px"
                        />
                      }
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleClose}
                      className="addJobsCancelButton"
                      variant="outlined"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                        minWidth: "120px",
                      }}
                      startIcon={
                        <img
                          className="hopbohoimg"
                          src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/cross-mark.png"
                          alt="add"
                          width="25px"
                        />
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </section>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          className="job-list"
          sx={{
            width: "100%",
          }}
        >
          {userlist.length > 0 &&
            userlist.map((u) => (
              <div className="HopBohoDiv" key={u.email}>
                <div className="applyJobDiv_text">
                  <p className="applyJobDiv_text_label">
                    <Tooltip title="UserName" placement="top">
                      <img
                        className="applyJobDiv_text_label_image"
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677828136/SoLinksDashboard/id-card_1.png"
                        alt="User Name"
                        height={25}
                        width={25}
                      />
                    </Tooltip>
                  </p>
                  <h4 className="applyJobDiv_text_val">{u.username}</h4>
                </div>

                <div className="applyJobDiv_text">
                  <p className="applyJobDiv_text_label">
                    <Tooltip title="Email" placement="top">
                      <img
                        className="applyJobDiv_text_label_image"
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677828137/SoLinksDashboard/email.png"
                        alt="Email"
                        height={25}
                        width={25}
                      />
                    </Tooltip>
                  </p>

                  <h4 className="applyJobDiv_text_val">{u.email}</h4>
                </div>

                <div className="applyJobDiv_text">
                  <p
                    className="applyJobDiv_text_label"
                    onClick={() => handleClickOpen(true, u)}
                  >
                    <Tooltip title="Edit" placement="top">
                      <img
                        className="applyJobDiv_text_label_image"
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                        alt="Email"
                        height={25}
                        width={25}
                      />
                    </Tooltip>
                  </p>

                  <h4 className="applyJobDiv_text_val">Edit User Details</h4>
                </div>
                <div className="applyJobDiv_text">
                  <p
                    className="applyJobDiv_text_label"
                    onClick={(e) => {
                      if (!checkuserauth(u)) {
                        deleteuserhandle(e, u.email);
                      } else {
                        setSnaksev("error");
                        setSnakmsg("Unauthorized");
                        setSnakbaropen(true);
                        return;
                      }
                    }}
                  >
                    <Tooltip title="Delete User" placement="top">
                      <img
                        className="applyJobDiv_text_label_image"
                        src="https://res.cloudinary.com/purnesh/image/upload/v1678794783/SoLinksDashboard/trash.png"
                        alt="Delete Icon"
                        height={25}
                        width={25}
                      />
                    </Tooltip>
                  </p>

                  <h4 className="applyJobDiv_text_val">Delete User Details</h4>
                </div>
              </div>
            ))}
        </Stack>
      </main>
      <Snackbar
        open={snakbaropen}
        autoHideDuration={4000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={snaksev}
          sx={{ width: "100%" }}
        >
          {snakmsg}
        </Alert>
      </Snackbar>
      {/* Modal */}
    </Base>
  );
};

export default User;
