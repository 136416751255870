import Axios from "axios";
import { AUTH_PATH, BASE_URL } from "./urlsandpath";

export const getcode = async (email) => {
  try {
    const res = await Axios.post(BASE_URL + AUTH_PATH.getcode, {
      email,
    });

    return res.data;
  } catch (error) {
    return { error: true, message: error.response.data };
  }
};

export const verifycode = async (email, code) => {
  try {
    const res = await Axios.post(BASE_URL + AUTH_PATH.verifycode, {
      email,
      code,
    });

    return res.data;
  } catch (error) {
    return { error: true, message: error.response.data };
  }
};
