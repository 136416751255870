import React, { useEffect, useRef, useState } from "react";
import Base from "../Base/Base";
import Singlearticle from "../components/Singlearticle";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/system/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import {
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  create,
  fetchimages,
  getarticlelist,
  update,
  uploadimage,
} from "../apis/articles";

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  minWidth: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
};

const Articles = () => {
  // States

  // Modal State
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  // State For Checking If Update Modal
  const [isupdatemodal, setIsupdatemodal] = useState(false);
  // Articles List State
  const [articles, setArticles] = useState([]);

  // Article Data for Saving New Article
  const [articledata, setArticledata] = useState({
    articleid: "",
    imagelink: "",
    articlelink: "",
    shorttitle: "",
    articlenumber: "",
    isspecial: false,
    islive: false,
  });

  // Snakbar State
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snaksev, setSnaksev] = useState("success");
  const [snakmsg, setSnakmsg] = useState("");

  // Reload State
  const [reload, setReload] = useState(0);

  // Image List

  const [imageList, setImageList] = useState([]);

  const [uplodedimage, setUplodedimage] = useState(null);

  const [imageReload, setImageReload] = useState(0);

  const [copytext, setCopytext] = useState("Copy");

  const [isuploading, setIsuploading] = useState(false);

  const [imagesuccess, setImagesuccess] = useState(false);

  // Image Upload Progress Bar
  const progressRef = useRef(() => {});
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(5);

  // Methods

  // Snakbar Handlers
  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbaropen(false);
  };

  // Method For Fetching Articles List
  const fetchimageslist = async (skip = 0, limit = 10) => {
    try {
      setImageList([]);
      const resp = await fetchimages(skip, limit);
      if (resp.error) {
        setImageList([]);
        return;
      }
      if (resp.statusCode === 200) {
        setImageList(resp.response);
      } else {
        setImageList([]);
      }
    } catch (error) {
      setImageList([]);
    }
  };
  const fetcharticles = async (skip = 0, limit = 10) => {
    try {
      setArticles([]);
      const resp = await getarticlelist(skip, limit);
      if (resp.error) {
        setArticles([]);
        return;
      }
      if (resp.statusCode === 200) {
        setArticles(resp.response);
      } else {
        setArticles([]);
      }
    } catch (error) {
      setArticles([]);
    }
  };

  // Save Article Handler
  const onsavehandler = async (e) => {
    e.preventDefault();
    let temp = articledata;
    if (!isupdatemodal) {
      delete temp.articleid;
    }
    if (temp.imagelink.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Image Link");
      setSnakbaropen(true);
      return;
    }
    if (temp.articlelink.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter Article Link");
      setSnakbaropen(true);
      return;
    }
    if (temp.shorttitle.trim() === "") {
      setSnaksev("warning");
      setSnakmsg("Please Enter  Short Title");
      setSnakbaropen(true);
      return;
    }
    if (typeof articledata.articlenumber === "string") {
      if (
        articledata.articlenumber.trim() === "" ||
        isNaN(parseInt(articledata.articlenumber.trim()))
      ) {
        setSnaksev("warning");
        setSnakmsg("Please Enter A Number");
        setSnakbaropen(true);
        return;
      }
    }

    temp.articlenumber = parseInt(articledata.articlenumber);
    let response;
    if (!isupdatemodal) {
      response = await create(temp);
    } else {
      response = await update(temp);
    }

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg(isupdatemodal ? "Article Updated!" : "Article Saved!");
      setSnakbaropen(true);
      setArticledata({
        articleid: "",
        imagelink: "",
        articlelink: "",
        shorttitle: "",
        articlenumber: "",
        isspecial: false,
        islive: false,
      });
      handleClose();
      setReload(reload + 1);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Saving Article!");
      setSnakbaropen(true);
    }
  };

  const imageuploadhandler = async (e) => {
    e.preventDefault();
    setIsuploading(true);
    setProgress(0);
    setBuffer(5);
    console.log("UPLOAD HANDLER", uplodedimage);

    if (!uplodedimage) {
      setSnaksev("warning");
      setSnakmsg("Please Select a Image");
      setSnakbaropen(true);
      setIsuploading(false);
      return;
    }

    const response = await uploadimage(uplodedimage);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      setIsuploading(false);
      return;
    }

    if (response.statusCode === 200) {
      setImagesuccess(true);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Uploading Image!");
      setSnakbaropen(true);
      setIsuploading(false);
    }
  };

  // Copy Handler

  const copyhandler = async (val) => {
    navigator.clipboard.writeText(val);
    setCopytext("Copied!");
    setTimeout(() => setCopytext("Copy"), 4000);
  };

  // Modal State Change Handlers
  const handleOpen = (isupdate, data) => {
    if (isupdate === true) {
      setIsupdatemodal(isupdate);
      setArticledata({
        articleid: data.articleid,
        imagelink: data.imagelink,
        articlelink: data.articlelink,
        shorttitle: data.shorttitle,
        articlenumber: data.articlenumber,
        isspecial: data.isspecial,
        islive: data.islive,
      });
    }
    setOpen(true);
  };
  const handleMediaOpen = () => {
    setOpenMedia(true);
  };

  const handleClose = () => {
    if (isupdatemodal === true) {
      setIsupdatemodal(!isupdatemodal);
    }
    setOpen(false);
  };

  const handleMediaClose = () => {
    setOpenMedia(false);
  };
  // Dynamic State Change Handler
  const changeHandler = (field, data) => {
    setArticledata({ ...articledata, [field]: data });
  };

  // Life Cycle Hooks
  useEffect(() => {
    fetcharticles();
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    fetchimageslist();
    // eslint-disable-next-line
  }, [imageReload]);

  useEffect(() => {
    progressRef.current = () => {
      if (isuploading) {
        if (progress <= 80) {
          const diff = Math.random() * 5;
          const diff2 = Math.random() * 5;
          setProgress(progress + diff);
          setBuffer(progress + diff + diff2);
        } else if (imagesuccess) {
          const diff = Math.random() * 5;
          const diff2 = Math.random() * 5;
          setProgress(progress + diff);
          setBuffer(progress + diff + diff2);
        }
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      setSnaksev("success");
      setSnakmsg("Image Upload Success");
      setSnakbaropen(true);
      setUplodedimage(null);
      setImageReload(imageReload + 1);
      setIsuploading(false);
      setImagesuccess(false);
    }
    // eslint-disable-next-line
  }, [progress, imagesuccess]);

  // debug
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Base>
      <Stack
        className="ArticleStack"
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        marginBottom={2}
        marginTop={2}
        paddingX={2}
      >
        <Button
          onClick={handleOpen}
          className="add_new_article"
          variant="outlined"
          sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
          startIcon={
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
              alt="add"
              width="20px"
            />
          }
        >
          Add New Article
        </Button>
        <Button
          onClick={handleMediaOpen}
          className="add_new_article"
          variant="outlined"
          sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
          startIcon={
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
              alt="add"
              width="20px"
            />
          }
        >
          Upload New Media
        </Button>
      </Stack>

      {articles.length === 0 && (
        <Stack justifyContent="center" alignItems="center" minHeight="50vh">
          <Typography fontWeight="bold">
            Currently There Is 0 Articles
          </Typography>
        </Stack>
      )}
      {articles.length !== 0 && (
        <section className="article_list_warpper">
          {Array.isArray(articles)
            ? articles.map((article) => (
                <Singlearticle
                  key={article.articleid}
                  handleOpen={handleOpen}
                  article={article}
                  setSnaksev={setSnaksev}
                  setSnakmsg={setSnakmsg}
                  setSnakbaropen={setSnakbaropen}
                />
              ))
            : null}
        </section>
      )}
      {/* Modal New Article*/}
      <Modal
        className="ArticlesModal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Hop_bohoModal">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.9rem",
              fontWeight: "bold",
              paddingTop: 2,
              paddingBottom: 2,
              background: "#212121",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            {isupdatemodal ? "Update Article" : "Add New Article"}
          </Box>
          <Stack paddingX={10} paddingY={4} spacing={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="article-link">
                Article Short Title
              </InputLabel>
              <Input
                id="article-link"
                label="Article Link"
                value={articledata.shorttitle}
                onChange={(e) => {
                  changeHandler("shorttitle", e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="article-link">Article Link</InputLabel>
              <Input
                id="article-link"
                label="Article Link"
                value={articledata.articlelink}
                onChange={(e) => {
                  changeHandler("articlelink", e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="image-link">Image Link</InputLabel>
              <Input
                id="image-link"
                label="Image Link"
                value={articledata.imagelink}
                onChange={(e) => {
                  changeHandler("imagelink", e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="article-number">Article Number</InputLabel>
              <Input
                id="article-number"
                label="Article Number"
                value={articledata.articlenumber}
                onChange={(e) => {
                  changeHandler("articlenumber", e.target.value);
                }}
              />
            </FormControl>
            <Stack direction="row" spacing={3}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Is Special"
                  value={articledata.isspecial}
                  variant="standard"
                  onChange={(e) => {
                    changeHandler("isspecial", e.target.value);
                  }}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Is Live"
                  value={articledata.islive}
                  variant="standard"
                  onChange={(e) => {
                    changeHandler("islive", e.target.value);
                  }}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            paddingBottom={4}
            spacing={5}
          >
            <Button
              onClick={(e) => onsavehandler(e)}
              className="add_new_article"
              variant="outlined"
              sx={{ fontWeight: "bold", fontSize: "0.7rem", minWidth: "15%" }}
              startIcon={
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/save.png"
                  alt="add"
                  width="25px"
                />
              }
            >
              Save
            </Button>

            <Button
              onClick={handleClose}
              className="add_new_article"
              variant="outlined"
              sx={{ fontWeight: "bold", fontSize: "0.7rem", minWidth: "15%" }}
              startIcon={
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/cross-mark.png"
                  alt="add"
                  width="25px"
                />
              }
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      {/* Modal New Image */}
      <Modal
        open={openMedia}
        onClose={handleMediaClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="uploadMediaModal">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.9rem",
              fontWeight: "bold",
              paddingTop: 2,
              paddingBottom: 2,
              background: "#212121",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            Media Section
          </Box>
          <Box sx={{ padding: 4 }}>
            <Stack
              alignItems="center"
              flexWrap="wrap"
              direction="row"
              maxHeight="65vh"
              overflow="scroll"
              justifyContent="center"
            >
              {/* Upload Media Input Section */}
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  overflow: "hidden",
                  marginX: "0.5rem",
                  marginY: "0.5rem",
                }}
              >
                <div className="file-upload-container">
                  <p className="file-upload-text">
                    Click Inside The Box To Upload Image
                  </p>
                  <Typography
                    sx={{
                      textAlign: "center",
                      paddingX: "0.5rem",
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "0.8rem",
                    }}
                  >
                    {uplodedimage ? uplodedimage.name : null}
                  </Typography>
                  <input
                    type="file"
                    name="file-upload"
                    onChange={(e) => {
                      setUplodedimage(e.target.files[0]);
                    }}
                  />
                  <Button
                    className="file-upload-btn"
                    onClick={(e) => imageuploadhandler(e)}
                    disabled={isuploading}
                    sx={{
                      position: "absolute",
                      backgroundColor: "#212121",
                      color: "#fff",
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: "5px,",
                      cursor: "pointer",
                      paddingY: "1rem",
                      textTransform: "none",
                      fontSize: "0.8rem",
                      width: "60%",
                      height: "20px",
                      "&:hover": {
                        backgroundColor: "#212121",
                      },
                    }}
                  >
                    {isuploading ? (
                      <LinearProgress
                        variant="buffer"
                        value={progress}
                        valueBuffer={buffer}
                        sx={{ width: "100%" }}
                      />
                    ) : (
                      "Upload"
                    )}
                  </Button>
                </div>
              </Box>
              {/* Upload Media Input Section */}
              {openMedia &&
                imageList.length > 0 &&
                imageList.map((img) => (
                  <Box
                    key={img.imageid}
                    sx={{
                      width: "200px",
                      height: "200px",
                      overflow: "hidden",
                      position: "relative",
                      marginX: "0.5rem",
                      marginY: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Tooltip title={copytext} placement="bottom" arrow>
                        <Button
                          onClick={() => copyhandler(img.secureurl)}
                          sx={{
                            backgroundColor: "#212121",
                            color: "#1976d2",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "5px,",
                            cursor: "pointer",
                            fontSize: "0.5rem",
                            textTransform: "none",
                            "&:hover": {
                              color: "#000",
                              backgroundColor: "#1976d2",
                            },
                          }}
                        >
                          Copy
                        </Button>
                      </Tooltip>
                    </Box>

                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={`${img.secureurl}`}
                      alt={img.secureurl}
                      loading="lazy"
                    />
                  </Box>
                ))}
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snakbaropen}
        autoHideDuration={4000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={snaksev}
          sx={{ width: "100%" }}
        >
          {snakmsg}
        </Alert>
      </Snackbar>
    </Base>
  );
};

export default Articles;
