import Axios from "axios";
import { ARTICLE_PATH, BASE_URL } from "./urlsandpath";
import { is_authenticated } from "../helpers/index";

let token = "";

const gettoken = () => {
  const user = is_authenticated();
  token = user.token;
};

export const getarticlelist = async (skip, limit) => {
  console.log("LIST");
  try {
    gettoken();
    const res = await Axios.get(
      BASE_URL +
        ARTICLE_PATH.get +
        `?skip=${skip}&limit=${limit}&token=${token}`
    );
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    return { error: true, message: error.response.data };
  }
};

export const create = async (data) => {
  console.log("CREATE");
  try {
    gettoken();
    data.creationdate = Date.now();
    const body = {
      token,
      data,
    };
    const res = await Axios.post(BASE_URL + ARTICLE_PATH.create, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};
export const update = async (data) => {
  console.log("UPDATE");
  try {
    gettoken();
    const body = {
      token,
      data,
    };
    const res = await Axios.put(BASE_URL + ARTICLE_PATH.update, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const togglestatus = async (articleid) => {
  console.log("TOGGLE");
  try {
    gettoken();
    const body = {
      token,
      articleid,
    };
    const res = await Axios.patch(BASE_URL + ARTICLE_PATH.togglestatus, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const fetchimages = async (skip, limit) => {
  console.log("Fetch Images");
  try {
    gettoken();
    const n_skip = skip ? parseInt(skip) : 0;
    const n_limit = limit ? parseInt(limit) : 10;
    const query = `?skip=${n_skip}&limit=${n_limit}&token=${token}`;

    const res = await Axios.get(BASE_URL + ARTICLE_PATH.getimages + query);
    return res.data;
  } catch (error) {
    return { error: true, message: error.response.data.response };
  }
};

export const uploadimage = async (file) => {
  console.log("Upload Image");
  try {
    gettoken();
    const formdata = new FormData();
    formdata.append("token", token);
    formdata.append("banner", file);

    const res = await Axios.post(BASE_URL + ARTICLE_PATH.uploadimage, formdata);
    return res.data;
  } catch (error) {
    return { error: true, message: error.response.data.response };
  }
};
