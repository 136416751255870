import Axios from "axios";
import { EVENT_PATH } from "./urlsandpath";
import { is_authenticated } from "../helpers/index";
import { BASE_URL } from "./urlsandpath";

let token = "";

const gettoken = () => {
  const user = is_authenticated();
  token = user.token;
};

export const create_event_details = async (event) => {
  console.log("EVENT DETAILS CREATE");

  try {
    gettoken();
    const body = {
      token,
      event,
    };
    const res = await Axios.post(BASE_URL + EVENT_PATH.create, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const update_event_details = async (event) => {
  console.log("UPDATE EVENT DETAILS");

  try {
    gettoken();
    const body = {
      token,
      event,
    };
    const res = await Axios.put(BASE_URL + EVENT_PATH.update, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
};

export const create_event_list = async (event) => {
  console.log("EVENT LIST CREATE");

  try {
    gettoken();
    const body = {
      token,
      event,
    };
    const res = await Axios.post(BASE_URL + EVENT_PATH.createeventlist, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
}

export const update_event_list = async (event) => {
  console.log("EVENT LIST UPDATE");

  try {
    gettoken();
    const body = {
      token,
      event,
    };
    const res = await Axios.put(BASE_URL + EVENT_PATH.updateeventlist, body);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
}

export const delete_event_list = async (eventid) => {
  console.log("EVENT LIST DELETE");

  try {
    gettoken();
    const res = await Axios.delete(BASE_URL + EVENT_PATH.deleteeventlist + `?eventid=${eventid}&token=${token}`);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
}

export const get_event_list = async () => {
  console.log("EVENT LIST FETCH");

  try {
    gettoken();
    const res = await Axios.get(BASE_URL + EVENT_PATH.geteventlist);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
}

export const get_event_data = async (eventid) => {
  console.log("EVENT DETAIL FETCH");

  try {
    gettoken();
    const res = await Axios.get(BASE_URL + EVENT_PATH.geteventdata + `?eventid=${eventid}`);
    return res.data;
  } catch (error) {
    console.log(error);
    let r;
    if (error.response.data.message === "Validation Failed") {
      r = { error: true, message: error.response.data.details.body[0].message };
    } else {
      r = { error: true, message: error.response.data.response };
    }
    return r;
  }
}

