import Axios from "axios";
import { PREVIEW_PATH, BASE_URL, BASE_URL_2 } from "./urlsandpath";
import { is_authenticated } from "../helpers/index";


let token = "";

const gettoken = () => {
    const user = is_authenticated();
    token = user.token;
};


export const getpreviewlist = async (skip, limit) => {
    console.log("LIST");

    try {
        const res = await Axios.get(
            BASE_URL_2 +
            PREVIEW_PATH.list +
            `?skip=${skip}&limit=${limit}`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        return { error: true, message: "Something Went Wrong !!!!" };
    }
};

export const get_status = async (aid) => {
    console.log("STATUS");
    try {
        const res = await Axios.get(
            BASE_URL +
            PREVIEW_PATH.getstatus +
            `?articleid=${aid}`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        return { error: true, message: "Something Went Wrong !!!!" };
    }
};

export const togglestatus = async (articleid) => {
    console.log("TOGGLE");
    try {
        gettoken();
        const body = {
            token,
            articleid,
        };
        const res = await Axios.post(BASE_URL + PREVIEW_PATH.togglestatus, body);
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        let r;
        if (error.response.data.message === "Validation Failed") {
            r = { error: true, message: error.response.data.details.body[0].message };
        } else {
            r = { error: true, message: error.response.data.response };
        }
        return r;
    }
};

export const getsinglearticle = async (aid, city) => {
    console.log("Single");

    try {

        const res = await Axios.get(
            BASE_URL_2 +
            PREVIEW_PATH.singlearticle +
            `/${city}/${aid}`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        return { error: true, message: "Something Went Wrong !!!!" };
    }
}