import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#000",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/burger.png"
              alt="Menu"
              height="25px"
              width="25px"
            />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            SoLinks by Sodelhi
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677234860/SoLinksDashboard/turn-left.png"
              alt="Overview"
              height="30px"
              width="30px"
            />
          </IconButton>
        </DrawerHeader>
        {/* Overview */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Overview" placement="right">
            <ListItemButton
              className="ListItemButton"
              onClick={() => navigate("/overview")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/research_1.png"
                  alt="Overview"
                  height="25px"
                  width="25px"
                />
              </ListItemIcon>
              <ListItemText primary="Overview" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        {/* Users */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Users" placement="right">
            <ListItemButton
              onClick={() => navigate("/users")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226390/SoLinksDashboard/superhero.png"
                  alt="Users"
                  height="25px"
                  width="25px"
                />
              </ListItemIcon>
              <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>

        {/* Article */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Articles" placement="right">
            <ListItemButton
              onClick={() => navigate("/articles")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/article.png"
                  alt="Article"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText primary="Articles" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>

        {/* HOP */}
        {/* <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Hop" placement="right">
            <ListItemButton
              onClick={() => navigate("/hop")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/festival.png"
                  alt="Hop"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText primary="HOP" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem> */}
        {/* Events */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Events" placement="right">
            <ListItemButton
              onClick={() => navigate("/events")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/festival.png"
                  alt="Events"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText primary="Events" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        {/* Hop */}
        {/* <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Boho" placement="right">
            <ListItemButton
              onClick={() => navigate("/boho")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/grocery.png"
                  alt="Boho"
                  height="25px"
                  width="25px"
                />
              </ListItemIcon>
              <ListItemText primary="Boho" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem> */}
        {/* jobs */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Jobs" placement="right">
            <ListItemButton
              onClick={() => navigate("/jobs")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/announcement.png"
                  alt="Jobs"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        {/* Article Preview  */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Preview Articles List" placement="right">
            <ListItemButton
              onClick={() => navigate("/preview/articles/list")}
              sx={{
                minHeight: "48",
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1684483343/SoLinksDashboard/articlepreview.png"
                  alt="Preview Articles"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Preview Articles List"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        {/* Settings  */}
        <ListItem
          className="listitem"
          disablePadding
          sx={{ display: "block", marginBottom: 1 }}
        >
          <Tooltip title="Settings" placement="right">
            <ListItemButton
              onClick={() => navigate("/settings")}
              sx={{
                minHeight: "48",
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677226390/SoLinksDashboard/settings_1.png"
                  alt="Settings"
                  height="30px"
                  width="30px"
                />
              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* <Jobs /> */}
      </Box>
    </Box>
  );
}
