import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import SOLOGO from "../assets/so_logo.svg";
import { BASE_BACKGROUND_COLOR_LIGHT } from "../constant";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getcode, verifycode } from "../apis/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { is_authenticated } from "../helpers/index";
// Setting-Up Alrt
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const location = useLocation()
  const { pathname } = location
  const imgArray = [
    "https://papers.co/wallpaper/papers.co-ba71-face-dark-illustration-art-red-36-3840x2400-4k-wallpaper.jpg",
    "https://i.pinimg.com/564x/64/6b/94/646b94faa2a690e5e00ea57e7d7ef10f.jpg",
    "https://images.hdqwalls.com/wallpapers/astronaut-waving-hand-minimal-4k-du.jpg",
    "https://wallpaperaccess.com/full/291948.jpg",
    "https://wallpaperaccess.com/full/2359053.jpg",
  ];
  const [imageNum] = useState(Math.floor(Math.random() * imgArray.length));

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const [data, setData] = useState({
    email: "",
    status: "initial",
  });

  const [code, setCode] = useState({
    code: "",
    status: "initial",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("");
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: "loading" }));
    // setData({ email: "", status: "sent" });
    // setData((current) => ({ ...current, status: "failure" }));
    if (!data.email || data.email === "") {
      setData((current) => ({ ...current, status: "failure" }));
      setMessage("Please Enter Your Email");
      setType("warning");
      setOpen(true);
      return;
    }

    const res = await getcode(data.email);

    if (res.error) {
      setData((current) => ({ ...current, status: "failure" }));
      setMessage(res.message.response);
      setType("error");
      setOpen(true);
      return;
    }
    if (res.statusCode === 200) {
      setData({ ...data, status: "sent" });
      setMessage(res.response);
      setType("success");
      setOpen(true);
      return;
    } else {
      setData((current) => ({ ...current, status: "failure" }));
      setMessage(res.response);
      setType("error");
      setOpen(true);
      return;
    }
  };

  const trycodeverification = async (event) => {
    event.preventDefault();
    setCode((current) => ({ ...current, status: "loading" }));
    // setData({ email: "", status: "sent" });
    // setData((current) => ({ ...current, status: "failure" }));
    if (!code.code || code.code === "") {
      setCode((current) => ({ ...current, status: "failure" }));
      setMessage("Please Enter Code");
      setType("warning");
      setOpen(true);
      return;
    }

    const res = await verifycode(data.email, code.code);

    if (res.error) {
      setCode((current) => ({ ...current, status: "failure" }));
      setMessage(res.message.response);
      setType("error");
      setOpen(true);
      return;
    }
    if (res.statusCode === 200) {
      setCode({ ...data, status: "sent" });
      setMessage("Code Verified");
      setType("success");
      setOpen(true);
      if (window !== "undefined") {
        const jsonstring = JSON.stringify(res.response);
        localStorage.setItem("user", jsonstring);
        setTimeout(() => navigate("/overview"), 6000);
      }
      return;
    } else {
      setCode((current) => ({ ...current, status: "failure" }));
      setMessage(res.response);
      setType("error");
      setOpen(true);
      return;
    }
  };
  useEffect(() => {
    if (pathname.split("/")[1] !== "preview-article") {
      if (is_authenticated()) {
        navigate("/overview");
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          className="login_grid"
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: `url(${imgArray[imageNum]})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#212121",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={7}>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={4}
            height="100%"
            sx={{
              backgroundColor: "#212121",
            }}
          >
            <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                className="login_text"
                sx={{
                  mr: 2,
                  color: BASE_BACKGROUND_COLOR_LIGHT,
                  fontSize: "2.5rem",
                }}
              >
                Welcome to
              </Typography>
              <img
                src={SOLOGO}
                alt="logo"
                width={50}
                height={50}
                className="login_img"
              />
            </Box>

            <form onSubmit={handleSubmit} className="login_button">
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.primary.plainColor,

                    color: BASE_BACKGROUND_COLOR_LIGHT,
                  })}
                >
                  Enter Your Email
                </FormLabel>
                <Input
                  sx={{
                    "--Input-decorator-childHeight": "45px",
                    width: "20rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: BASE_BACKGROUND_COLOR_LIGHT,
                  }}
                  placeholder="abc@gmail.com"
                  type="email"
                  required
                  value={data.email}
                  onChange={(event) =>
                    setData({ email: event.target.value, status: "initial" })
                  }
                  error={data.status === "failure"}
                  endDecorator={
                    <Button
                      color="primary"
                      loading={data.status === "loading"}
                      type="submit"
                      onClick={() => { }}
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        color: BASE_BACKGROUND_COLOR_LIGHT,
                      }}
                    >
                      Get Code
                    </Button>
                  }
                />
                {data.status === "failure" && (
                  <FormHelperText
                    sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
                  >
                    Oops! something went wrong, please try again later.
                  </FormHelperText>
                )}

                {data.status === "sent" && (
                  <FormControl sx={{ marginTop: 1 }}>
                    <FormLabel
                      sx={(theme) => ({
                        "--FormLabel-color":
                          theme.vars.palette.primary.plainColor,
                        color: "white",
                      })}
                    >
                      Enter Your Code
                    </FormLabel>
                    <Input
                      sx={{
                        "--Input-decorator-childHeight": "45px",
                        color: BASE_BACKGROUND_COLOR_LIGHT,
                      }}
                      type="text"
                      required
                      value={code.code}
                      onChange={(event) =>
                        setCode({
                          code: event.target.value,
                          status: "initial",
                        })
                      }
                      error={code.status === "failure"}
                      endDecorator={
                        <Button
                          onClick={trycodeverification}
                          variant="solid"
                          color="primary"
                          loading={code.status === "loading"}
                          sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            color: BASE_BACKGROUND_COLOR_LIGHT,
                          }}
                        >
                          Submit
                        </Button>
                      }
                    />
                    {code.status === "failure" && (
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.vars.palette.danger[400],
                        })}
                      >
                        Wrong Code
                      </FormHelperText>
                    )}

                    {code.status === "sent" && (
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.vars.palette.primary[400],
                        })}
                      >
                        Login Success Redirecting....
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              </FormControl>
            </form>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
