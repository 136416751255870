// import MyComponent from "./components/ViewPort";

import Main from "./Routes/Main";

function App() {
  return (
    <div className="App">
      {/* <MyComponent> */}
      <Main />
      {/* </MyComponent> */}
    </div>
  );
}

export default App;
