import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Base from '../Base/Base'
import Singlepreviewarticle from '../components/Singlepreviewarticle';
import { getpreviewlist } from '../apis/preview';

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ArticlePreview = () => {

    const [list, setList] = useState([])

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };


    // Snakbar State
    const [snakbaropen, setSnakbaropen] = useState(false);
    const [snaksev, setSnaksev] = useState("success");
    const [snakmsg, setSnakmsg] = useState("");

    // Snakbar Handlers
    const handleSnakbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnakbaropen(false);
    };


    const fetcharticles = async (skip, limit) => {


        let response = { error: true, message: "Error Fetching Data" };

        response = await getpreviewlist(skip, limit);

        if (response.error) {
            setSnaksev("error");
            setSnakmsg(response.message);
            setSnakbaropen(true);
            return;
        }

        if (response.data) {
            // console.log(response.data);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setList(response.data)
            setSnaksev("success");
            setSnakmsg("Success");
            setSnakbaropen(true);
        } else {
            setSnaksev("error");
            setSnakmsg("Error Fetching Data");
            setSnakbaropen(true);
        }
    };


    useEffect(() => {
        if (page > 1) {
            let skip = 10 * (page - 1)
            fetcharticles(skip, 10)
        } else {
            let skip = 0, limit = 10
            fetcharticles(skip, limit)
        }
    }, [page])

    return (
        <Base>
            <Box className="article_preview_base">
                {list.map(a => (<Singlepreviewarticle key={a._id} title={a.title} imagesrc={a.imageUrl} aid={a._id} setSnakbaropen={setSnakbaropen}
                    setSnaksev={setSnaksev}
                    setSnakmsg={setSnakmsg}
                    city={a.primaryCity}
                />))}
            </Box>
            <Box className="articale_preview_pagination">
                <Pagination count={1000} page={page} onChange={handleChange} size="small" color="primary" />
            </Box>
            <Snackbar
                open={snakbaropen}
                autoHideDuration={4000}
                onClose={handleSnakbarClose}
            >
                <Alert
                    onClose={handleSnakbarClose}
                    severity={snaksev}
                    sx={{ width: "100%" }}
                >
                    {snakmsg}
                </Alert>
            </Snackbar>
        </Base>
    )
}

export default ArticlePreview
