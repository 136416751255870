import React, { useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  // backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(10% - 15px)",
}));

const MobileSidebar = (props) => {
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const getactiveroute = () => {
    const { pathname } = location;
    return pathname.split("/")[1];
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller position="fixed" open={open}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              // onClick={handleDrawerOpen}
              edge="start"
              onClick={toggleDrawer(true)}
            >
              <img
                src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/burger.png"
                alt="Menu"
                height="30px"
                width="30px"
              />
            </IconButton>
          </Puller>
          <Typography
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "flex-end",
              textTransform: "capitalize",
              color: "#1976d2",
              fontWeight: "600",
            }}
          >
            {getactiveroute()}
          </Typography>
        </StyledBox>

        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "bottom" ? "auto" : 250,
            }}
            role="presentation"
          >
            <List>
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Overview" placement="right">
                  <ListItemButton
                    className="ListItemButton"
                    onClick={() => navigate("/overview")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/research_1.png"
                        alt="Overview"
                        height="25px"
                        width="25px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Overview"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Users */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Users" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/users")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226390/SoLinksDashboard/superhero.png"
                        alt="Users"
                        height="25px"
                        width="25px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Users"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Article */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Articles" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/articles")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/article.png"
                        alt="Article"
                        height="30px"
                        width="30px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Articles"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Events */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Events" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/events")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/festival.png"
                        alt="Events"
                        height="30px"
                        width="30px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Events"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Hop
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Boho" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/boho")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226389/SoLinksDashboard/grocery.png"
                        alt="Boho"
                        height="25px"
                        width="25px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Boho"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem> */}
              {/* jobs */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Jobs" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/jobs")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226388/SoLinksDashboard/announcement.png"
                        alt="Jobs"
                        height="30px"
                        width="30px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Jobs"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Preview Articles List  */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Preview Articles List" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/preview/articles/list")}
                    sx={{
                      minHeight: "48",
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1684483343/SoLinksDashboard/articlepreview.png"
                        alt="Preview Articles List"
                        height="30px"
                        width="30px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Preview Articles List"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {/* Settings  */}
              <ListItem
                className="listitem"
                disablePadding
                sx={{ display: "block", marginBottom: 1 }}
              >
                <Tooltip title="Settings" placement="right">
                  <ListItemButton
                    onClick={() => navigate("/settings")}
                    sx={{
                      minHeight: "48",
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/purnesh/image/upload/v1677226390/SoLinksDashboard/settings_1.png"
                        alt="Settings"
                        height="30px"
                        width="30px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Settings"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>

          {/* <Skeleton variant="rectangular" height="100%" /> */}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

MobileSidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MobileSidebar;
