import React from "react";
import Base from "../Base/Base";
import User from "../components/User";

const Users = () => {
  return (
    <Base>
      <User />
    </Base>
  );
};

export default Users;
