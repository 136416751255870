import React from "react";
import propTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { is_authenticated } from "../helpers/index";
const ProtectedRoute = ({ component: Component }) => {
  const location = useLocation()
  const { pathname } = location
  if (is_authenticated() || pathname.split("/")[1] === "preview-article") {
    return <Component />;
  }
  return <Navigate to="/" replace />;
};

ProtectedRoute.propTypes = {
  component: propTypes.func.isRequired,
};

export default ProtectedRoute;
