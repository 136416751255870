export const BASE_TEXT_COLOR_LIGHT = "#000";
export const BASE_TEXT_COLOR_DARK = "#fff";
export const BASE_TEXT_COLOR_LIGHT_2 = "rgba(0,0,0,0.6)";
export const BASE_BACKGROUND_COLOR_LIGHT = "#FFFAFA";
export const BASE_BACKGROUND_COLOR_DARK = "#212121";

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
