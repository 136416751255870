import React, { useEffect, useState } from "react";
import Base from "../Base/Base";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { FormControl, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { get_event_data, get_event_list } from "../apis/events";
import { create_event_details } from "../apis/events";
import { update_event_details } from "../apis/events";
import { create_event_list } from "../apis/events";
import { update_event_list } from "../apis/events";
import { delete_event_list } from "../apis/events";

// Creating Custom Snakbar Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AllEvents = () => {
  // States
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [eventid, setEventid] = useState("");
  const [isdataupdate, setIsdataupdate] = useState(false);
  const [eventData, setEventData] = useState({
    bookstalllink: null,
    cityname: "",
    eventid: "",
    performAtEvent: null,
    socialmediainternlink: null,
    ticketlink: null,
    volunteerlink: null,
  });
  const [eventListData, setEventListData] = useState({
    eventname: "",
    eventid: "",
  });
  const [reload, setReload] = useState(0);

  // Snakbar State
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snaksev, setSnaksev] = useState("success");
  const [snakmsg, setSnakmsg] = useState("");

  // Methods
  const handleOpen = (update = false, e) => {
    if (update) {
      setIsdataupdate(true);
      setEventListData({
        ...eventListData,
        eventid: e.eventid,
        eventname: e.eventname,
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (isdataupdate) {
      setIsdataupdate(false);
    }
    setEventListData({ eventname: "", eventid: "" });
    setOpen(false);
  };

  const handleMediaClose = () => {
    if (isdataupdate) {
      setIsdataupdate(false);
      setEventid("");
    }
    setOpenMedia(false);
    setEventData({
      bookstalllink: "",
      cityname: "",
      eventid: "",
      performAtEvent: "",
      socialmediainternlink: "",
      ticketlink: "",
      volunteerlink: "",
    });
  };

  // Snakbar Handlers
  const handleSnakbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbaropen(false);
  };

  const editseeevent = (update = false, eid) => {
    setOpenMedia(true);
    if (update) {
      setIsdataupdate(update);
      setEventid(eid);
    }
  };

  // Change Handler

  const changeHandler = (feild, value) => {
    setEventData({ ...eventData, [feild]: value });
  };

  // API Methods
  const populate_event_list = async () => {
    let response = { error: true, message: "Error Fetching List" };

    response = await get_event_list();

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setEventList(response.response);
    } else {
      setSnaksev("error");
      setSnakmsg("Error Fetching Event List!");
      setSnakbaropen(true);
      return;
    }
  };

  const populate_event_data = async (eventid) => {
    let response = { error: true, message: "Error Fetching Data" };

    response = await get_event_data(eventid);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      // console.log(response.response);
      const data = response.response;
      setEventData({
        ...eventData,
        bookstalllink: data.bookstalllink,
        cityname: data.cityname,
        eventid: data.eventid,
        performAtEvent: data.performAtEvent,
        socialmediainternlink: data.socialmediainternlink,
        ticketlink: data.ticketlink,
        volunteerlink: data.volunteerlink,
      });
    } else {
      setSnaksev("error");
      setSnakmsg("Error Fetching Event Data!");
      setSnakbaropen(true);
      return;
    }
  };

  // Save Event Handler
  const onsavehandler = async (e) => {
    e.preventDefault();
    const temp = eventData;

    if (
      typeof temp.bookstalllink === "string" &&
      temp.bookstalllink.trim() === ""
    ) {
      temp.bookstalllink = null;
    }
    if (
      typeof temp.performAtEvent === "string" &&
      temp.performAtEvent.trim() === ""
    ) {
      temp.performAtEvent = null;
    }
    if (
      typeof temp.socialmediainternlink === "string" &&
      temp.socialmediainternlink.trim() === ""
    ) {
      temp.socialmediainternlink = null;
    }
    if (typeof temp.ticketlink === "string" && temp.ticketlink.trim() === "") {
      temp.ticketlink = null;
    }
    if (
      typeof temp.volunteerlink === "string" &&
      temp.volunteerlink.trim() === ""
    ) {
      temp.volunteerlink = null;
    }
    let response;
    if (!isdataupdate) {
      response = await create_event_details(eventData);
    } else {
      response = await update_event_details(eventData);
    }

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg(isdataupdate ? "Event Updated!" : "Event Saved!");
      setSnakbaropen(true);
      setEventData({
        bookstalllink: "",
        cityname: "",
        eventid: "",
        performAtEvent: "",
        socialmediainternlink: "",
        ticketlink: "",
        volunteerlink: "",
      });
      handleMediaClose();
    } else {
      setSnaksev("error");
      setSnakmsg("Error Saving Event Data!");
      setSnakbaropen(true);
    }
  };

  // Save Event List
  const saveeventlist = async (e) => {
    e.preventDefault();
    const temp = eventListData;
    let response;
    if (!isdataupdate) {
      delete temp.eventid;
      response = await create_event_list(temp);
    } else {
      response = await update_event_list(temp);
    }

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg(isdataupdate ? "Event Updated!" : "Event Saved!");
      setSnakbaropen(true);
      setEventListData({
        eventname: "",
        eventid: "",
      });
      setReload(reload + 1);
      handleClose();
    } else {
      setSnaksev("error");
      setSnakmsg("Error Saving Event List!");
      setSnakbaropen(true);
    }
  };

  // Delete Event List
  const deleteevent = async (id) => {
    let response = await delete_event_list(id);

    if (response.error) {
      setSnaksev("error");
      setSnakmsg(response.message);
      setSnakbaropen(true);
      return;
    }

    if (response.statusCode === 200) {
      setSnaksev("success");
      setSnakmsg("Event Deleted");
      setSnakbaropen(true);
      setEventListData({
        eventname: "",
        eventid: "",
      });
      setReload(reload + 1);
      handleClose();
    } else {
      setSnaksev("error");
      setSnakmsg("Error Deleting Event!");
      setSnakbaropen(true);
    }
  };
  useEffect(() => {
    populate_event_list();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reload > 0) populate_event_list();
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    if (eventid !== "") {
      populate_event_data(eventid);
    }
    // eslint-disable-next-line
  }, [eventid]);

  // useEffect(() => {
  //   console.log(eventListData);
  //   // eslint-disable-next-line
  // }, [eventListData]);

  return (
    <Base>
      <Stack
        className="EventStack"
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        marginBottom={2}
        paddingX={2}
      >
        <Button
          onClick={() => handleOpen(false, "")}
          className="add_new_event"
          variant="outlined"
          sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
          startIcon={
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
              alt="add"
              width="20px"
            />
          }
        >
          Add New Event
        </Button>
        <Button
          onClick={() => editseeevent(false, "")}
          className="add_new_event"
          variant="outlined"
          sx={{ fontWeight: "bold", fontSize: "0.7rem" }}
          startIcon={
            <img
              src="https://res.cloudinary.com/purnesh/image/upload/v1677494513/SoLinksDashboard/add.png"
              alt="add"
              width="20px"
            />
          }
        >
          Add Event Data
        </Button>
      </Stack>
      <Box sx={{ marginTop: "1rem" }}>
        {eventList.length > 0 &&
          eventList.map((e) => (
            <div
              className="HopBohoDiv"
              key={e._id}
              style={{ marginBottom: "1rem" }}
            >
              <div className="applyJobDiv_text">
                <p className="applyJobDiv_text_label">
                  <Tooltip title="Event Name" placement="top">
                    <img
                      className="applyJobDiv_text_label_image"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677828136/SoLinksDashboard/id-card_1.png"
                      alt="User Name"
                      height={25}
                      width={25}
                    />
                  </Tooltip>
                </p>
                <h4 className="applyJobDiv_text_val">{e.eventname}</h4>
              </div>
              <div className="applyJobDiv_text">
                <p
                  className="applyJobDiv_text_label"
                  onClick={() => editseeevent(true, e.eventid)}
                >
                  <Tooltip title="Edit" placement="top">
                    <img
                      className="applyJobDiv_text_label_image"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                      alt="Email"
                      height={25}
                      width={25}
                    />
                  </Tooltip>
                </p>

                <h4 className="applyJobDiv_text_val">
                  See & Edit Event Details
                </h4>
              </div>
              <div className="applyJobDiv_text">
                <p
                  className="applyJobDiv_text_label"
                  onClick={() => handleOpen(true, e)}
                >
                  <Tooltip title="Edit" placement="top">
                    <img
                      className="applyJobDiv_text_label_image"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677568434/SoLinksDashboard/pencil.png"
                      alt="Email"
                      height={25}
                      width={25}
                    />
                  </Tooltip>
                </p>

                <h4 className="applyJobDiv_text_val">Edit Event</h4>
              </div>
              <div className="applyJobDiv_text">
                <p
                  className="applyJobDiv_text_label"
                  onClick={() => {
                    console.log(e);
                    const v = window.confirm(
                      "Are you sure you want to delete event?"
                    );
                    if (v) deleteevent(e.eventid);
                  }}
                >
                  <Tooltip title="Delete Event" placement="top">
                    <img
                      className="applyJobDiv_text_label_image"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1678794783/SoLinksDashboard/trash.png"
                      alt="Delete Icon"
                      height={25}
                      width={25}
                    />
                  </Tooltip>
                </p>

                <h4 className="applyJobDiv_text_val">Delete Event</h4>
              </div>
            </div>
          ))}
      </Box>
      {/* Modal1 */}
      <Modal
        className="Modal"
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            className="ModalBox"
            sx={style}
            component="form"
            noValidate
            autoComplete="off"
          >
            <Typography
              sx={{
                minWidth: 100,
                textAlign: "center",
                backgroundColor: "black",
                color: "#fff",
                padding: 2,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {isdataupdate ? "Update Event" : "Add New Event"}
            </Typography>
            <div className="ModalForm">
              <TextField
                id="outlined-basic"
                label="Add Event Name"
                variant="outlined"
                size="small"
                fullWidth
                value={eventListData.eventname}
                onChange={(e) =>
                  setEventListData({
                    ...eventListData,
                    eventname: e.target.value,
                  })
                }
              />

              <div className="modalFormButton">
                <Button
                  variant="outlined"
                  onClick={(e) => saveeventlist(e)}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    minWidth: "120px",
                  }}
                  startIcon={
                    <img
                      className="hopbohoimg"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/save.png"
                      alt="add"
                      width="25px"
                    />
                  }
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  // onClick={close}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    minWidth: "120px",
                  }}
                  startIcon={
                    <img
                      className="hopbohoimg"
                      src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/cross-mark.png"
                      alt="add"
                      width="25px"
                    />
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* Modal 2 */}
      <Modal
        open={openMedia}
        onClose={handleMediaClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Hop_bohoModal">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.9rem",
              fontWeight: "bold",
              paddingTop: 2,
              paddingBottom: 2,
              background: "#212121",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            {isdataupdate ? "Update Event Data" : "Add New Event Data"}
          </Box>
          <Stack
            paddingX={10}
            paddingY={4}
            spacing={3}
            sx={{ maxHeight: "70vh", overflowY: "scroll" }}
            className="hopBohoModalForm"
          >
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                select
                label="Event Name"
                variant="outlined"
                size="small"
                value={eventData.eventid}
                onChange={(e) => changeHandler("eventid", e.target.value)}
              >
                <MenuItem value="">Select Event</MenuItem>
                {eventList.map((option) => (
                  <MenuItem key={option.eventid} value={option.eventid}>
                    {option.eventname}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                size="small"
                value={eventData.cityname}
                onChange={(e) => changeHandler("cityname", e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Book Stall Link"
                variant="outlined"
                size="small"
                value={eventData.bookstalllink || ""}
                onChange={(e) => changeHandler("bookstalllink", e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Volunteer Link"
                variant="outlined"
                size="small"
                value={eventData.volunteerlink || ""}
                onChange={(e) => changeHandler("volunteerlink", e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Ticket Link"
                variant="outlined"
                size="small"
                value={eventData.ticketlink || ""}
                onChange={(e) => changeHandler("ticketlink", e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Social Media Intern Link"
                variant="outlined"
                size="small"
                value={eventData.socialmediainternlink || ""}
                onChange={(e) =>
                  changeHandler("socialmediainternlink", e.target.value)
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Perform At Event Link"
                variant="outlined"
                size="small"
                value={eventData.performAtEvent || ""}
                onChange={(e) =>
                  changeHandler("performAtEvent", e.target.value)
                }
              />
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            paddingBottom={4}
            spacing={5}
          >
            <Button
              onClick={(e) => onsavehandler(e)}
              className="add_new_article"
              variant="outlined"
              sx={{ fontWeight: "bold", fontSize: "0.7rem", minWidth: "15%" }}
              startIcon={
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/save.png"
                  alt="add"
                  width="25px"
                />
              }
            >
              Save
            </Button>
            <Button
              onClick={handleMediaClose}
              className="add_new_article"
              variant="outlined"
              sx={{ fontWeight: "bold", fontSize: "0.7rem", minWidth: "15%" }}
              startIcon={
                <img
                  src="https://res.cloudinary.com/purnesh/image/upload/v1677582887/SoLinksDashboard/cross-mark.png"
                  alt="add"
                  width="25px"
                />
              }
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        open={snakbaropen}
        autoHideDuration={4000}
        onClose={handleSnakbarClose}
      >
        <Alert
          onClose={handleSnakbarClose}
          severity={snaksev}
          sx={{ width: "100%" }}
        >
          {snakmsg}
        </Alert>
      </Snackbar>
    </Base>
  );
};

export default AllEvents;
