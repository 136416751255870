import { MONTHS } from "../constant";

export const is_authenticated = () => {
  if (window !== "undefined") {
    if (window.localStorage && window.localStorage.getItem("user")) {
      const user = JSON.parse(window.localStorage.getItem("user"));
      return user;
    }
  }
  return false;
};

export const dateconversion = (d) => {
  const con_date = new Date(d);
  const date = con_date.getDate();
  const month = MONTHS[con_date.getMonth()];
  const year = con_date.getFullYear();

  return date + "-" + month + "-" + year;
};
